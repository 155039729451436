// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T_SaHlDzAEJoTGDoBUSA{width:40px;grid-template-rows:0px 40px 0px;border-radius:40px;transform:translateY(-7px)}.YnstiuXdTQt48Lm920YA{display:none}.VJUk1XkNAsYRhKuFSLDz{transform:translateY(7px)}.E7hnGRL7SIrYQSp2jMDx{border-radius:40px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/elbowDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,kBAAA","sourcesContent":[".elbowBlock {\r\n    width: 40px;\r\n    grid-template-rows: 0px 40px 0px;\r\n    border-radius: 40px;\r\n    transform: translateY(-7px);\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.translatedConnections {\r\n    transform: translateY(7px);\r\n}\r\n\r\n.roundSelectionBorder {\r\n    border-radius: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"elbowBlock": `T_SaHlDzAEJoTGDoBUSA`,
	"hidden": `YnstiuXdTQt48Lm920YA`,
	"translatedConnections": `VJUk1XkNAsYRhKuFSLDz`,
	"roundSelectionBorder": `E7hnGRL7SIrYQSp2jMDx`
};
export default ___CSS_LOADER_EXPORT___;
